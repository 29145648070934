import { mapGetters } from 'vuex';
import RouteGeneratorMixin from './RouteGeneratorMixin';

export default {
  mixins: [RouteGeneratorMixin],

  props: {
    filter: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      filterMenu: false,
    };
  },

  computed: {
    ...mapGetters(['user', 'organizations', 'devices', 'plans']),

    routeQuery() {
      return this.$route.query;
    },

    isFiltered() {
      // check if thre is no null value in filter
      return Object.keys(this.routeQuery)
        .filter((f) => f !== 'page' && f !== 'per_page' && f !== 'keyword')
        .some((v) => this.routeQuery[v] != null);
    },
  },
  methods: {
    setPropertyToNull(obj, key) {
      // set to null, when clearable clicked
      setTimeout(() => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = null;
      }, 0);
    },
  },
};
