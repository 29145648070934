<template>
  <v-dialog
    v-if="isAdmin"
    v-model="dialog"
    max-width="600px"
    persistent
  >
    <v-card :loading="loading">
      <v-card-title>
        <span>{{ formTitle }}</span>
        <v-spacer />
        <v-icon @click="closeDialog">
          mdi-close
        </v-icon>
      </v-card-title>

      <ErrorAlert />

      <v-card-text class="pt-5 pb-0">
        <v-form
          ref="form"
          v-model="formValidity"
        >
          <v-text-field
            v-model="form.name"
            :rules="nameRules"
            :counter="30"
            :disabled="loading"
            outlined
            dense
            label="Name"
            @keypress.enter="saveItem"
          />
          <v-text-field
            v-model="form.email"
            :rules="emailRules"
            :disabled="loading"
            outlined
            dense
            label="Email"
            @keypress.enter="saveItem"
          />
          <v-text-field
            v-model="form.password"
            label="Password"
            :disabled="loading"
            outlined
            :hint="!isEdit ? 'Leave blank to set default password. Default password wii be sent to user\'s email.' : ''"
            :persistent-hint="!isEdit ? true : false"
            dense
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword= !showPassword"
            @keypress.enter="saveItem"
          />
          <template v-if="isSuperadmin">
            <v-autocomplete
              v-model="form.organization_id"
              :items="organizations"
              :disabled="loading"
              auto-select-first
              outlined
              dense
              clearable
              item-text="name"
              item-value="id"
              label="Organization"
              :search-input.sync="searchInput.organization"
              @click:clear="setPropertyToNull(form,'organization_id')"
            />
            <v-select
              v-model="level"
              :items="levelOptions"
              :disabled="loading"
              outlined
              dense
              label="Level"
            />
          </template>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn
          :disabled="loading"
          text
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          :loading="loading"
          class="primary"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import FormMixin from '../../mixins/FormMixin';

export default {
  name: 'UserForm',
  mixins: [FormMixin],

  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      form: {
        name: '',
        email: '',
        password: null,
        organization_id: null,
        is_organization_admin: false,
        is_superadmin: false,
      },
      defaultForm: {
        name: '',
        email: '',
        password: null,
        organization_id: null,
        is_organization_admin: false,
        is_superadmin: false,
      },
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => v.length <= 30 || 'Name must be less than 30 characters',
      ],
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
      ],
      level: 'user',
      levelOptions: [
        {
          text: 'User',
          value: 'user',
        },
        {
          text: 'Organization Admin',
          value: 'organization_admin',
        },
        {
          text: 'Superadmin',
          value: 'superadmin',
        },
      ],
      showPassword: false,
      searchInput: {
        organization: null,
      },
    };
  },

  computed: {
    ...mapGetters(['user', 'organizations']),

    isAdmin() {
      return this.user.is_superadmin || this.user.is_organization_admin;
    },

    isSuperadmin() {
      return this.user.is_superadmin;
    },
  },

  watch: {
    level(val) {
      if (val === 'superadmin') {
        this.form.is_superadmin = true;
        this.form.is_organization_admin = false;
      } else if (val === 'organization_admin') {
        this.form.is_superadmin = false;
        this.form.is_organization_admin = true;
      } else {
        this.form.is_superadmin = false;
        this.form.is_organization_admin = false;
      }
    },

    dialog(bool) {
      if (bool) {
        this.clearSearchInput();
        this.repopulateLevel();
      }
    },
  },

  created() {
    if (!this.isSuperadmin) {
      // hide level superadmin, when not superadmin
      this.levelOptions = this.levelOptions.filter((l) => l.value !== 'superadmin');
    }
  },

  methods: {
    addData() {
      this.removeUnauthorizedProperty();
      return this.$api.addUser(this.formData);
    },

    updateData() {
      this.removeUnauthorizedProperty();
      return this.$api.updateUser({
        id: this.form.id,
        ...this.formData,
      });
    },

    clearSearchInput() {
      this.searchInput.organization = null;
    },

    repopulateLevel() {
      if (this.form.is_superadmin) {
        this.level = 'superadmin';
      } else if (this.form.is_organization_admin) {
        this.level = 'organization_admin';
      } else {
        this.level = 'user';
      }
    },

    removeUnauthorizedProperty() {
      // key only for superadmin authority
      if (!this.isSuperadmin) {
        delete this.form.organization_id;
        delete this.form.is_superadmin;
      }
    },
  },
};
</script>
