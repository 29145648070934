<template>
  <v-text-field
    v-model="filter.keyword"
    label="Search..."
    :hint="hint"
    solo
    clearable
    persistent-hint
    @keypress.enter="processSearch()"
    @click:clear="clearSearchKeyword()"
  >
    <template v-slot:append>
      <v-slide-x-reverse-transition>
        <v-btn
          v-if="filter.keyword"
          color="primary"
          icon
          :to="routeGenerator()"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-slide-x-reverse-transition>
    </template>
  </v-text-field>
</template>

<script>
import RouteGeneratorMixin from '../mixins/RouteGeneratorMixin';

export default {
  name: 'SearchKeyword',
  mixins: [RouteGeneratorMixin],
  props: {
    filter: {
      type: Object,
      required: true,
    },
    searchable: {
      type: Array,
      default() {
        return ['name'];
      },
    },
  },

  computed: {
    hint() {
      return `Search using ${this.searchable.join(', ')}`;
    },
  },

  methods: {
    processSearch() {
      this.$router.push(this.routeGenerator()).catch((err) => { });
    },

    clearSearchKeyword() {
      setTimeout(() => {
        this.$router.push(this.routeGenerator()).catch((err) => { });
      }, 0);
    },
  },
};
</script>
